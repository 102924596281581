import $ from 'jquery';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import Player from 'video.js/dist/types/player';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import Lazyload from 'lazyload';
// import VConsole from 'vconsole';
import throttle from 'lodash/throttle';
import initWxSdk from './wxSdk';
import initAudio from './audio';
import {
  nativePlayVideo,
  nativePreviewImage,
  nativeVoiceBroadcast,
  nativeOpenMediaDetailPage,
  getUserInfo,
  nativeOpenServicePage,
  toNewsDetail,
  toTopic,
  toFunDetail,
} from './appSdk';
import {
  browerVersion,
  getBrowerAppId,
  getSingleValueFromInput,
  getUrlQuery,
  initYoumeng,
  uuid,
  initRootFontSize,
  isHideDownloadGuide,
  getRmtNewsUrlParams,
} from './utils';
import {
  fetchChannleJson,
  fetchChannelNewsJson,
  fetchSiteJson,
  fetchMediaInfoV3,
  fetchAddSubscribe,
  fetchAddReadCountByLog,
  fetchCommentList,
  fetchContentPropertie,
  fetchRelatedContent,
  fetchDelSubscribe,
  fetchBelongTopic,
} from '../apis/api';
import initNewsTemplate from './newsTemplate';
import '../scss/textDetail.scss';

const commentRight = require('../assets/images/comment-right.png');
const commentLike = require('../assets/images/comment-like.png');
const videoPlay = require('../assets/images/video-play.png');
const belingTopicLogo = require('../assets/images/related-topic.png');
const belingTopicArrow = require('../assets/images/related-topic-arrow.png');

// eslint-disable-next-line no-new
// new VConsole();
Swiper.use([FreeMode]);
const version = browerVersion();
let appId = uuid();
let userId = '';

/**
 * 获取最新的三条新闻
 * @param downloadUrl
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initNewsLatest = (downloadUrl: string) => {
  if (isHideDownloadGuide()) {
    return;
  }
  if (version.mobile && !version.myApp) {
    fetchChannelNewsJson(process.env.LATEST_NEWS_CHANNEL_CODE as string).then(
      (res: RMT.ListJson) => {
        const latest3News = [];
        for (let i = 0; i < res.list.length; i += 1) {
          const contentTypes = [1, 2, 4, 5, 6, 7];
          const item = res.list[i];
          if (contentTypes.includes(Number(item.contentType))) {
            latest3News.push({
              id: item.id,
              contentType: item.contentType,
              mListpattern: item.data.mListpattern,
              cover:
                item.data.mCoverImg_s ||
                item.data.mCoverImg ||
                item.data.mCoverImg1_s ||
                item.data.mCoverImg1 ||
                process.env.DEFAULT_IMAGE_16_9,
              title: item.data.title,
            });
          }
          if (latest3News.length >= 3) {
            break;
          }
        }
        const { clientWidth } = document.body;
        const htmls = latest3News.map((item) => {
          const imgDom = `<img src="${process.env.DEFAULT_IMAGE_16_9}" loading="lazy" srcset="${item.cover}" onerror="this.src='${process.env.DEFAULT_IMAGE_16_9}'" class="swiper-news-image">`;
          const titleDom = `<div class="swiper-news-title">${item.title}</div>`;
          const btnDom = `<div class="swiper-news-button">打开</div>`;
          const swiperBox = `<div class="swiper-news-box" style="width:${clientWidth}px">${imgDom}${titleDom}${btnDom}</div>`;
          const aDom = `<a name="download-link" data-href="${downloadUrl}" data-id="${item.id}" data-type="${item.contentType}" data-pattern="${item.mListpattern}">${swiperBox}</a>`;
          const launchDom = `<div name="launch-app-btn" data-style="launch-swiper-style" class="swiper-launch-btn">${aDom}</div>`;
          const emptyDom = `<div class="swiper-empty-box">${launchDom}</div>`;
          const swiperSlide = `<div class="swiper-slide article-latest-slide">${emptyDom}</div>`;
          return swiperSlide;
        });
        $('.article-latest').show();
        $('.container').css('padding-bottom', '65px');
        $('#swiper-wrapper').html(htmls.join(''));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const swiper = new Swiper('#article-latest-swiper', {
          autoplay: {
            delay: 5000,
          },
          observer: true,
          observeSlideChildren: true,
          observeParents: true,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          },
          on: {
            init() {
              initWxSdk({
                launchAppBtn: [{ attr: 'class', value: 'swiper-empty-box' }],
              });
            },
          },
        });
      },
    );
  }
};

/**
 * 生成评论html
 * @param item
 * @returns
 */
const getCommentHtml = (item: RMT.CommentList) => {
  const commentAvatarHtml = `<img class="comment-avatar lazyload" src="${process.env.DEFAULT_AVATAR}" data-src="${item.headImg}" onerror="this.src='${process.env.DEFAULT_AVATAR}'"
    alt="${item.username}">`;
  const commentReplyHtml = `<div class="comment-reply-box">
      <div class="launch-app-btn" name="launch-app-btn" data-style="comment-reply-style">
        <a name="download-link" class="download-link">
          <div class="comment-reply">回复<img style="width: 16px;height: 16px;" src="${commentRight}" alt="回复"></div>
        </a>
      </div>
    </div>`;
  const commentUsernameHtml = `<span class="comment-nickname">${item.username}</span>`;
  const commentLikeHtml = `<div class="like-unlike">
      <div class="comment-like">
        <div class="launch-app-btn" name="launch-app-btn" data-style="like-unlike-style">
          <a name="download-link" class="download-link">
            <img class="comment-like-icon" src="${commentLike}" alt="点赞">
          </a>
        </div>
      </div>
    </div>`;
  const commentHeaderHtml = `<div class="comment-header">${commentUsernameHtml}${commentLikeHtml}</div>`;
  const commentEmotion = `<img class="comment-emotion" src="${item.txt}" />`;
  const commentBodyHtml = `<div class="comment-body">${
    item.emotion ? commentEmotion : item.txt
  }</div>`;
  const commentDetailHtml = `<div class="comment-detail">${commentHeaderHtml}${commentBodyHtml}${commentReplyHtml}</div>`;
  return `<div class="comment-box">${commentAvatarHtml}${commentDetailHtml}</div>`;
};

/**
 * 获取稿件评论
 * @param downloadUrl
 */
const initComment = (downloadUrl: string) => {
  if (!version.myApp) {
    $('#article-comment').show();
    const newsId = getSingleValueFromInput('newsId');
    const siteId = getSingleValueFromInput('siteId');
    fetchCommentList(newsId, siteId, appId, 1, 10).then((res) => {
      if (Number(res.status) === 200 && res.list.length) {
        $('#comment-list').empty();
        const commentHtmls = res.list.map((item) => getCommentHtml(item));
        $('#comment-list').html(commentHtmls.join(''));
        $('.more-comment-box ').show();
        $('#article-comment')
          .find('a[name="download-link"]')
          .attr('data-href', downloadUrl);
        if (!version.mobile) {
          $('#article-comment')
            .find('a[name="download-link"]')
            .attr('href', downloadUrl);
        }
        // eslint-disable-next-line no-new
        new Lazyload($('#article-comment').find('.comment-avatar'));
        initWxSdk({
          launchAppBtn: [
            {
              attr: 'class',
              value: 'comment-like',
            },
            {
              attr: 'class',
              value: 'comment-reply-box',
            },
            {
              attr: 'id',
              value: 'more-comment-box',
            },
          ],
        });
      }
    });
  }
};

/**
 * 初始化sitejson
 */
const initSiteJson = () => {
  if (!version.myApp) {
    fetchSiteJson().then((res: Site.SiteJson) => {
      // initNewsLatest(res.downloadUrl);
      initComment(res.downloadUrl);
      if (isHideDownloadGuide()) {
        return;
      }
      const headerGuideBox = $('#header-guide-box');
      if (!headerGuideBox || !res.name) {
        return;
      }
      const { clientWidth } = document.body;
      if (version.weixin) {
        $('.text-launch-app').css({ width: `${clientWidth * 0.85}px` });
      }
      $('#article-launch-app-box')
        .find('a[name="download-link"]')
        .attr('data-href', res.downloadUrl);
      if (version.mobile) {
        $('#article-operate')
          .find('a[name="download-link"]')
          .attr('data-href', res.downloadUrl);
        $('.article-operate-list').css({
          width: `${clientWidth > 650 ? 620 : clientWidth - 30}px`,
        });
        initWxSdk({
          launchAppBtn: [
            {
              attr: 'id',
              value: 'article-operate',
            },
          ],
        });
      }
      initWxSdk({
        launchAppBtn: [
          {
            attr: 'id',
            value: 'article-launch-app-box',
          },
        ],
      });
      // 设置下载链接
      headerGuideBox
        .find('a[name="download-link"]')
        .attr('data-href', res.downloadUrl)
        .attr('alt', res.name);
      // 设置引导图片
      headerGuideBox
        .find('img[name=guide-image]')
        .attr('src', res.guidePic)
        .attr('alt', res.name);
      headerGuideBox.find('img[name=guide-image]').on('load', (evt) => {
        let imageHeight = '';
        let imageWidth = '';
        const { naturalHeight, naturalWidth } =
          evt.currentTarget as HTMLImageElement;
        if (clientWidth > 650) {
          imageWidth = '650px';
          imageHeight = `${(650 / naturalWidth) * naturalHeight}px`;
        } else {
          imageWidth = `${clientWidth}px`;
          imageHeight = `${(clientWidth / naturalWidth) * naturalHeight}px`;
        }
        $(evt.currentTarget).css({
          height: imageHeight,
          width: imageWidth,
        });
        initWxSdk({
          launchAppBtn: [
            {
              attr: 'id',
              value: 'header-guide-box',
            },
          ],
        });
      });
      // 点击关闭按钮的事件
      headerGuideBox.find('img[name="close-guide"]').each((index, el) => {
        $(el).on('click', (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          headerGuideBox.hide();
        });
      });
    });
  }
};

/**
 * 初始化领导媒体号的功能
 * @returns
 */
const initMediaLeader = () => {
  if (version.myApp) {
    const siteId = getSingleValueFromInput('siteId');
    const mediaLeaderLinkDoms = $('.exist-media-leader-link');
    // 获取媒体号基础信息并根据userId判断是否已经关注
    if (userId && mediaLeaderLinkDoms && mediaLeaderLinkDoms.length) {
      const mediaInfoPromises: Promise<false | Media.MediaData>[] = [];
      mediaLeaderLinkDoms.each((_, el) => {
        const { id } = el.dataset;
        if (id) {
          mediaInfoPromises.push(fetchMediaInfoV3(siteId, id, appId, userId));
        } else {
          mediaInfoPromises.push(
            new Promise((resolve) => {
              resolve(false);
            }),
          );
        }
      });
      Promise.all(mediaInfoPromises).then((reslist) => {
        reslist.forEach((item, index) => {
          if (!item) {
            return;
          }
          if (Number(item.isSubscribe)) {
            const el = mediaLeaderLinkDoms[index];
            $(el).find('.media-leader-subscribe').hide();
            $(el).find('.media-leader-del-subscribe').css('display', 'flex');
          }
        });
      });
    }
    // 关注按钮的点击事件，若未登录则调用app登录功能，若已登录则调用接口关注
    $('.media-leader-subscribe').on('click', (evt) => {
      evt.stopPropagation();
      const mediaLeaderDom = $(evt.currentTarget).parents(
        '.exist-media-leader-link',
      );
      const { id } = mediaLeaderDom[0].dataset;
      if (id && userId) {
        fetchAddSubscribe(siteId, id, appId, userId).then((res) => {
          if (Number(res.status) === 200) {
            mediaLeaderDom.find('.media-leader-subscribe').hide();
            mediaLeaderDom
              .find('.media-leader-del-subscribe')
              .css('display', 'flex');
          }
        });
      } else if (id && !userId) {
        getUserInfo((result) => {
          if (result && result.userId) {
            userId = result.userId;
          }
        });
      }
    });
    // 取消关注按钮的点击事件，若未登录则调用app登录功能（逻辑上不存在该事件），若已登录则调用接口取消关注
    $('.media-leader-del-subscribe').on('click', (evt) => {
      evt.stopPropagation();
      const mediaLeaderDom = $(evt.currentTarget).parents(
        '.exist-media-leader-link',
      );
      const { id } = mediaLeaderDom[0].dataset;
      if (id && userId) {
        fetchDelSubscribe(siteId, id, appId, userId).then((res) => {
          if (Number(res.status) === 200) {
            mediaLeaderDom
              .find('.media-leader-subscribe')
              .css('display', 'flex');
            mediaLeaderDom.find('.media-leader-del-subscribe').hide();
          }
        });
      } else if (id && !userId) {
        getUserInfo((result) => {
          if (result && result.userId) {
            userId = result.userId;
          }
        });
      }
    });
  }
};

const addReadCount = () => {
  const newsId = getSingleValueFromInput('newsId');
  const contentType = getSingleValueFromInput('contentType');
  const siteId = getSingleValueFromInput('siteId');
  if (newsId && contentType && siteId) {
    fetchAddReadCountByLog(newsId, Number(contentType), siteId);
  }
};

/**
 * 处理所有媒体号的基础信息
 */
const initAllMedia = () => {
  // 普通媒体号轮播效果
  const notLeaderMediaLinkDoms = $('.media-box-link');
  const notLeaderMediaListDom = $('#media-list');
  if (
    notLeaderMediaLinkDoms &&
    notLeaderMediaLinkDoms.length &&
    notLeaderMediaListDom &&
    notLeaderMediaListDom.length
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const swiper = new Swiper('#media-list', {
      slidesPerView: 'auto',
      freeMode: true,
      centerInsufficientSlides: true,
      breakpoints: {
        320: {
          spaceBetween: 15,
        },
        650: {
          spaceBetween: 20,
        },
      },
    });
  }
  const mediaLeaderLinkDoms = $('.exist-media-leader-link');
  // todo 待鸿蒙实现
  if (version.myApp && !version.harmonyOS) {
    // 若在app内，则修改领导媒体号链接
    if (mediaLeaderLinkDoms && mediaLeaderLinkDoms.length) {
      mediaLeaderLinkDoms.each((_: number, el) => {
        const mediaHref = $(el).attr('href');
        $(el).attr({
          // eslint-disable-next-line no-script-url
          href: 'javascript:void(0);',
          'data-href': mediaHref,
        });
      });
      $('.exist-media-leader-link').on('click', (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const { id } = evt.currentTarget.dataset;
        if (id) {
          nativeOpenMediaDetailPage(id, 'media');
        }
        return false;
      });
    }
    // 若在app内，则修改普通媒体号链接
    notLeaderMediaLinkDoms.each((_: number, el) => {
      const mediaHref = $(el).attr('href');
      $(el).attr({
        href: '',
        'data-href': mediaHref,
      });
    });
    // 若在app内，处理普通媒体号的点击事件
    $('.media-box-link').on('click', (evt) => {
      evt.preventDefault();
      const { id } = evt.currentTarget.dataset;
      if (id) {
        nativeOpenMediaDetailPage(id, 'media');
      }
    });
    // 媒体号领导人在app内点击则跳转到媒体号详情页
  } else {
    // 若不在app内，领导媒体号的关注修改为更多文章
    $('.media-leader-subscribe').hide();
    $('.media-leader-del-subscribe').hide();
    $('.media-leader-more').css('display', 'flex');
  }
};

const Component = videojs.getComponent('Component');
// 自定义播放按钮，当在APP中点击时调用APP端的视频播放
class CustomPlayButton extends Component {
  divDom: null | Element = null;

  constructor(
    player: Player,
    options: { fn?: () => any; children?: any[]; className?: string } = {},
  ) {
    super(player, options);
    (this as any).on('click', () => {
      if (options.fn) {
        options.fn();
      }
    });
  }

  createEl() {
    this.divDom = videojs.dom.createEl('div', {
      className: 'custom-play-button vjs-custom-play-button',
    });
    const imgDom = videojs.dom.createEl('img', {
      style: 'width: 32px; height: 32px;',
      src: videoPlay,
      className: 'vjs-custom-play-btn',
    });
    videojs.dom.appendContent(this.divDom, imgDom);
    return this.divDom;
  }
}
videojs.registerComponent('CustomPlayButton', CustomPlayButton);
/**
 * 初始化视频
 */
const initVideo = () => {
  // 引入video.js
  $('#article-content').find('video').addClass('video-js vjs-fluid').attr({
    'webkit-playsinline': true,
    playsinline: true,
    'x5-video-player-type': 'h5',
  });
  $('video').each((index, el) => {
    const player = videojs(el, {
      fluid: true,
      controls: !version.myApp || version.harmonyOS, // 当在app中时不显示原生播放按钮
      playbackRates: [0.75, 1, 1.25, 1.5, 2],
      poster: el.getAttribute('poster') || process.env.DEFAULT_IMAGE_16_9,
    });
    player.on('play', () => {
      const players = videojs.getAllPlayers();
      players.forEach((item: Player) => {
        if (!item.paused() && player.id() !== item.id()) {
          item.pause();
        }
      });
    });
    // 当在app中时显示自定义播放按钮，点击后唤起app视频播放
    // todo 待鸿蒙实现
    if (version.myApp && !version.harmonyOS) {
      player.addChild('CustomPlayButton', {
        fn: () => {
          const { src } = player.getMedia();
          if (src.length) {
            const item = (
              src as unknown as { src: string; poster: string }[]
            )[0];
            const url = item.src;
            nativePlayVideo(url);
          }
        },
      });
    }
  });
};

/**
 * 初始化图片
 */
const initImage = () => {
  // 图片懒加载
  // eslint-disable-next-line no-new
  new Lazyload();
  // 获取稿件内的全部图片，若图片在a标签的超链接内，则跳过该图片
  const imgDomList = $('#article-content')
    .find('img')
    .not((_: number, el: HTMLElement) => {
      return $(el).parent().hasClass('vjs-poster');
    })
    .not((_: number, el: HTMLElement) => {
      return $(el).hasClass('vjs-custom-play-btn');
    })
    .not((_: number, el: HTMLElement) => {
      return $(el)
        .parents()
        .is((index: number, a) => {
          return !!$(a).attr('href');
        });
    })
    .not((_: number, el: HTMLElement) => {
      return (
        $(el).hasClass('aggregated-split-arrow') ||
        $(el).hasClass('aggregated-date-headerimg') ||
        $(el).hasClass('aggregated-date-inner-icon')
      );
    });
  const imgList: { url: string; title: string }[] = [];
  imgDomList.each((index: number, el: HTMLImageElement) => {
    imgList.push({
      url: el.dataset.src || el.src || '',
      title: el.title,
    });
  });
  // 图片点击事件

  imgDomList.on('click', (evt) => {
    const src = evt.currentTarget.dataset.src || evt.currentTarget.src;
    const index = imgList.findIndex((item) => item.url === src);
    const urls = imgList.map((item) => item.url);
    if (version.weixin) {
      wx.previewImage({
        // 调用微信预览图片
        urls,
        current: urls[index],
      });
    } else if (version.myApp) {
      nativePreviewImage(imgList, index);
    }
  });
};

/**
 * 语音播报功能
 */
const initVoiceBroadcast = () => {
  if (version.myApp) {
    $('#voice-broadcast').show();
    $('#voice-broadcast').on('click', () => {
      if (!version.myApp) {
        console.error('语音播报仅支持在APP中运行');
        return;
      }
      const videoJsDoms = $('.video-js');
      const videoTexts: string[] = [];
      videoJsDoms.each((_: number, el: HTMLElement) => {
        videoTexts.push($(el).text());
      });
      const newsId = getSingleValueFromInput('newsId');
      const title = $('#article-title').text();
      let content = $('#article-content').text();
      videoTexts.forEach((text) => {
        content = content.replace(text, '');
      });
      if (newsId && title && content) {
        nativeVoiceBroadcast(newsId, title, content);
      }
    });
  } else {
    $('#voice-broadcast').hide();
  }
};

/**
 * 获取相关频道推荐
 * @returns
 */
const initChannelList = () => {
  if (!version.myApp) {
    $('#related-channel').show();
    fetchChannleJson().then((res: RMT.Channel) => {
      const { list } = res;
      const subDomain = getSingleValueFromInput('subDomain');
      const htmlList = list.map((item) => {
        let style = 'font-size: 0.875rem';
        if (version.mobile && item.name.length > 8) {
          style = 'font-size: 0.6rem';
        } else if (version.mobile && item.name.length > 6) {
          style = 'font-size: 0.65rem';
        } else if (version.mobile && item.name.length > 4) {
          style = 'font-size: 0.75rem';
        }
        const divDom = `<div class="channel-box" data-code="${item.alias}" style="${style}">${item.name}</div>`;
        return `<a target="_blank" class="channel-link" href="${subDomain}/channel/${item.alias}/index.html">${divDom}</a>`;
      });
      $('#channel-list').html(htmlList.join(''));
    });
  }
};

/**
 * 初始化服务号
 */
const initServer = () => {
  if (version.myApp) {
    $(
      '.click-vote,.click-questionnaire,.click-sign-up,.click-media-skip',
    ).show();
    $('.click-server-skip').css({
      display: 'flex',
    });
    $('#article-content').on(
      'click',
      '.click-vote,.click-questionnaire,.click-sign-up,.click-media-skip,.click-server-skip',
      (evt) => {
        const code = $(evt.currentTarget).find('code');
        if (code && code.length) {
          const { store } = code[0].dataset;
          if (store) {
            const storeArr = store.split('&');
            const storeObj = {} as { [propName: string]: string };
            storeArr.forEach((item) => {
              const [key, value] = item.split('=');
              storeObj[key as string] = decodeURIComponent(value);
            });
            nativeOpenServicePage(storeObj);
          }
        }
      },
    );
  }
};

/**
 * 监听iframe message
 */
const initIframeMessageListener = () => {
  window.addEventListener('message', (evt) => {
    const {
      data: { action, value, url },
    } = evt;
    switch (action) {
      case 'setFontSize': {
        const fontsize = parseInt(value, 10) || 10;
        initRootFontSize(fontsize);
        break;
      }
      case 'setIframeSize': {
        if (url) {
          const iframe = $(`iframe[src="${url}"]`);
          const { height, width } = value;
          if (typeof height === 'string') {
            iframe.css({ height });
          } else if (typeof height === 'number') {
            iframe.css({ height: `${height}px` });
          }
          if (typeof width === 'string') {
            iframe.css({ width });
          } else if (typeof width === 'number') {
            iframe.css({ width: `${width}px` });
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  });
};

/**
 * 初始化浏览量
 */
const initReadCount = () => {
  if (!version.myApp) {
    $('#read-like-number').hide();
    // return;
  }
  const readLikeNumber = $('#read-like-number');
  const newsId = getSingleValueFromInput('newsId');
  const channelId = getSingleValueFromInput('channelId');
  if (newsId && channelId) {
    fetchContentPropertie(newsId, channelId).then((res) => {
      const { praiseCount, visitCount, isShield, status } = res;
      if (isShield === 1) {
        $('#article-comment').remove();
      }
      if (readLikeNumber && readLikeNumber.length && Number(status) === 200) {
        $('#read-number-text').text(visitCount);
        $('#like-number-text').text(praiseCount);
      }
    });
  }
};

/**
 * 初始化相关稿件
 */
const initRelatedContent = () => {
  if (!version.myApp) {
    const newsId = getSingleValueFromInput('newsId');
    fetchRelatedContent(newsId).then((res: any) => {
      if (Number(res.status) === 200) {
        if (res.list.length) {
          $('#related-content').show();
        }
        res.list.forEach((item: any) => {
          $('#content-list').append(initNewsTemplate(item.data));
        });
        const images = $('.t-news-image');
        const newImages = images.filter(
          (index, el) => $(el).attr('src') !== $(el).attr('data-src'),
        );
        // eslint-disable-next-line no-new
        new Lazyload(newImages);
        const tVideos = $('.t-news-video');
        if (tVideos && tVideos.length) {
          tVideos.each((index, el) => {
            const player = videojs(el, {
              // fluid: true,
              controls: true,
              aspectRatio: '16:9',
              playbackRates: [0.75, 1, 1.25, 1.5, 2],
              poster:
                el.getAttribute('poster') || process.env.DEFAULT_IMAGE_16_9,
            });
            player.on('play', () => {
              const players = videojs.getAllPlayers();
              players.forEach((item: Player) => {
                if (!item.paused() && player.id() !== item.id()) {
                  item.pause();
                }
              });
            });
          });
        }
      }
    });
  }
};

/**
 * 初始化相关专题
 */
const initRelatedTopic = () => {
  if ($('#related-topic')) {
    if (version.myApp) {
      $('.relate-topic-link').on('click', (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        const href = $(evt.currentTarget).attr('href');
        if (href) {
          const params = getRmtNewsUrlParams(href);
          const mListpattern = $(evt.currentTarget).attr('data-pattern');
          const title = $(evt.currentTarget).attr('data-title') || '';
          const isChild = $(evt.currentTarget).attr('data-ischild');
          const detailJson = $(evt.currentTarget).attr('data-json') || '';
          if (params) {
            if (version.ios) {
              toNewsDetail(
                6,
                params.newsId,
                Number(mListpattern),
                title,
                Number(isChild),
                detailJson,
              );
            } else if (version.android) {
              toTopic(params.newsId, Number(isChild), detailJson);
            }
          }
        }
      });
    }
  }
};

/**
 * 初始化相关活动
 */
const initRelatedActivity = () => {
  if (!version.myApp && $('#related-activity')) {
    $('#related-activity').show();
  }
};

/**
 * 本地开发测试
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initLocalTest = () => {
  return new Promise((resolve) => {
    fetch(
      'https://xinhuacheng.gzcmc.net/json/content/2023/12/18/5a5b3978fdb24b1285e7623efc31c777.detailjson',
    )
      .then((res) => res.json())
      .then((res) => {
        let { txt } = res;
        const imgList = txt.match(
          /(<img[\s\S]*?src\s*=\s*["']|\[img\])(.*?)(["'][\s\S]*?>|\[\/img\])/g,
        );
        imgList.forEach((img: string) => {
          const list = img.match(
            /src\s*=\s*["'](.*?)["'](.*title\s*=\s*["'](.*?)["'])*/,
          ) as string[];
          if (list.length >= 4) {
            txt = txt.replace(
              img,
              `<img src="${process.env.DEFAULT_IMAGE_16_9}" data-src="${
                list[1]
              }" class="lazyload" title="${list[3] || ''}">`,
            );
          }
        });
        $('#article-content').append(txt);
        $('.article-title').text(res.title);
        $('.article-source').text(`来源：${res.sourceName}`);
        $('.article-time').text(res.publishTime);
        resolve(true);
      });
  });
};

// 设置所属专题
const initInnerTopic = async () => {
  const articleInnerTopic = $('.article-belong-topics');
  if (!articleInnerTopic || !articleInnerTopic.length) {
    return;
  }
  const res = await fetchBelongTopic(getSingleValueFromInput('newsId'));
  if (!res || !res.list.length) {
    articleInnerTopic.remove();
    return;
  }
  const htmls = res.list.map((item) => {
    const logoHtml = `<img class="article-belong-topic-logo" src="${belingTopicLogo}" alt="">`;
    const zhuantiHtml = '<span>专题: </span>';
    const leftHtml = `<div class="article-belong-left">${logoHtml}${zhuantiHtml}</div>`;
    const centerHtml = `<div class="article-belong-center">${item.data.title}</div>`;
    const rightHtml = `<img src="${belingTopicArrow}" class="article-belong-topic-arrow">`;
    const boxHtml = `<div class="article-belong-topic">${leftHtml}${centerHtml}${rightHtml}</div>`;
    return `<a data-pattern="${item.data.mListpattern}" data-title="${item.data.title}" data-ischild="${item.data.ischild}" data-json="${item.data.detailJsonPath}" class="article-belong-topic-link" href="${item.data.url}" target="_blank">${boxHtml}</a></a>`;
  });
  articleInnerTopic.append(htmls.join(''));
  if (version.myApp) {
    $('.article-belong-topic-link').on('click', (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      const href = $(evt.currentTarget).attr('href');
      if (href) {
        const params = getRmtNewsUrlParams(href);
        if (params) {
          const mListpattern = $(evt.currentTarget).attr('data-pattern');
          const title = $(evt.currentTarget).attr('data-title') || '';
          const isChild = $(evt.currentTarget).attr('data-ischild');
          const detailJson = $(evt.currentTarget).attr('data-json') || '';
          if (version.ios) {
            toNewsDetail(
              6,
              params.newsId,
              Number(mListpattern),
              title,
              Number(isChild),
              detailJson,
            );
          } else if (version.android) {
            toTopic(params.newsId, Number(isChild), detailJson);
          }
        }
      }
    });
  }
};

const initAggregatedVideo = () => {
  const videos = $('.aggregated-video');
  $(videos).each((_, el) => {
    $(el).on('click', (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
    });
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initAggregatedLink = () => {
  $('.aggregated-content').on('click', '.aggregated-link', () => {});
};

const initScroll = () => {
  const { innerHeight } = window;
  document.addEventListener(
    'scroll',
    throttle(() => {
      const videos = $('.video-js');
      for (let index = 0; index < videos.length; index += 1) {
        const el = videos[index];
        const { y, height } = el.getBoundingClientRect();
        if (y < innerHeight - height && y >= 0) {
          const player = videojs.getPlayer(el);
          try {
            if (player.paused()) {
              player.play();
            }
            break;
          } catch (e) {
            console.error(e);
          }
        } else if (y < 0 || y > innerHeight) {
          const player = videojs.getPlayer(el);
          if (!player.paused()) {
            player.pause();
          }
        }
      }
    }, 250),
  );
};

/**
 * 打开新闻详情页
 * @param contentType 稿件类型
 * @param id 稿件id
 * @param mListpattern 稿件展示方式
 * @param title 稿件标题
 * @param isChild 是否为子栏目
 * @param detailJsonPath detailjson路径
 */
const initALink = () => {
  $('a').on('click', (evt) => {
    const href = $(evt.currentTarget).attr('href');
    if (version.iframe) {
      // eslint-disable-next-line no-script-url
      if (href && href !== 'javascript:void(0)' && window.top) {
        evt.preventDefault();
        window.top.open(href);
      }
    } else if (
      href &&
      version.myApp &&
      !version.harmonyOS &&
      (href.includes('media-dynamic.html') ||
        href.includes('media-subject.html') ||
        href.includes('media-mine.html'))
    ) {
      // todo 待鸿蒙实现
      const toDetailParams: Record<string, string | number> = {
        contentType: 55,
      };
      const hrefParams = getUrlQuery(href);

      if (href.includes('media-dynamic')) {
        toDetailParams.id = hrefParams.dynamicId;
        toDetailParams.showType = 0;
        toDetailParams.type = hrefParams.type;
      }

      if (href.includes('media-subject')) {
        toDetailParams.topicId = hrefParams.subjectId;
        toDetailParams.showType = 1;
      }

      if (href.includes('media-mine')) {
        toDetailParams.userId = hrefParams.uuid;
        toDetailParams.showType = 2;
      }
      toFunDetail(toDetailParams);
      evt.preventDefault();
    }
  });
};

// 自执行函数
(async () => {
  // 测试代码;
  // await initLocalTest();
  if (version.myApp && version.userId) {
    userId = version.userId;
  }
  const staticType = getSingleValueFromInput('staticType');
  if (Number(staticType)) {
    initSiteJson();
    initVoiceBroadcast();
    getBrowerAppId((browerId) => {
      appId = browerId;
      initMediaLeader();
      addReadCount();
    });
  }
  if (!Number(staticType)) {
    const query = getUrlQuery();
    if (!Number(query.isMobile)) {
      const previewSubtitle = $('.preview-subtitle');
      if (previewSubtitle) {
        previewSubtitle.show();
      }
      const previewKeyword = $('.preview-keyword');
      if (previewKeyword) {
        previewKeyword.show();
      }
      const previewDescription = $('.preview-description');
      if (previewDescription) {
        previewDescription.show();
      }
    }
  }
  initALink();
  initVideo();
  initAggregatedVideo();
  initScroll();
  // initAggregatedLink();
  initImage();
  if ($('.extend-audio-p').length) {
    initAudio($('.extend-audio-p'));
  } else {
    initAudio($('audio'));
  }
  initServer();
  initAllMedia();
  initIframeMessageListener();
  if (getSingleValueFromInput('subDomain')) {
    initChannelList();
    initRelatedContent();
    initReadCount();
  }
  initRelatedTopic();
  initInnerTopic();
  initRelatedActivity();
  initYoumeng();
  initWxSdk({
    wxShare: {
      title: getSingleValueFromInput('shareTitle') || document.title,
      desc: getSingleValueFromInput('shareDesc') || '',
      imgUrl: (getSingleValueFromInput('shareImgUrl') ||
        process.env.DEFAULT_SHARE_IMAGE) as string,
    },
  });
})();
