import { browerVersion } from './utils';

// app 端暴露的函数列表
const appMethod = {
  TO_NEWS_DETAIL: 'toNewsDetail', // 打开新闻详情页
  JS_NATIVE_SHARE: 'jsNativeShare', // 打开原生分享
  NATIVE_ADD_CREDITS: 'nativeAddCredits', // 增加投票、报名、问卷调查, data积分
  NATIVE_SHARE_ELECTOR: 'nativeShareElector', // 分享投票人
  GET_SCREEN_HEIGHT: 'getScreenHeight', // 获取webView宽高
  NATIVE_OPEN_WEB: 'nativeOpenWeb', // 打开网页
  JS_GET_LOCATION_INFO: 'jsGetLocationInfo', // 获取用户定位
  NATIVE_OPEN_SERVICE_PAGE: 'nativeOpenServicePage', // 打开服务页
  JS_UPLOAD_RECORD: 'jsUploadRecord', // 上传录音
  JS_STOP_PLAY_RECORD: 'jsStopPlayRecord', // 停止播放录音
  JS_PLAY_RECORD: 'jsPlayRecord', // 播放录音
  JS_STOP_RECORD: 'jsStopRecord', // 停止录音
  JS_START_RECORD: 'jsStartRecord', // 开始录音
  NATIVE_IS_SUBSCRIBE: 'nativeIsSubscribe', // 订阅状态刷新
  NATIVE_OPEN_ACTIVE_PAGE: 'nativeOpenActivityPage', // 打开活动页面
  NATIVE_VOICE_BROADCAST: 'nativeVoiceBroadcast', // 调用语音播放
  NATIVE_PLAY_VIDEO: 'nativePlayVideo', // 调用视频播放
  NATIVE_PREVIEW_IMAGE: 'nativePreviewImage', // 调用图片浏览器
  NATIVE_OPEN_MEDIA_DETAIL_PAGE: 'nativeOpenMediaDetailPage', // 打开媒体号主页
  NATIVE_OPEN_MEDIA_PAGE: 'nativeOpenMediaPage', // 打开媒体号主页
  GET_USER_PHONE: 'getUserPhone', // 获取用户手机号
  GET_LOGIN_USER_ID: 'getLoginUserId', // 获取用户userId
  GET_USER_INFO: 'getUserInfo',
  JS_DR_GET_ACCESS_TOKEN: 'jsDRGetAccessToken', // 活动中心获取token
  TO_TOPIC: 'toTopic',
  NATIVE_SCAN_IMAGE: 'nativeScanImage', // 调用扫描图片功能
};

type AppMethodKey = keyof typeof appMethod;
/**
 * 统一调用app方法
 * @param method
 * @param options
 * @returns
 */
const askAppToDo = (
  methodKey: AppMethodKey,
  options?: { [propName: string]: any } | string,
) => {
  const version = browerVersion();
  if (!version.myApp) {
    console.error(new Error('当前环境不在融媒体app中'));
    return false;
  }
  if (!Object.keys(appMethod).includes(methodKey)) {
    console.error(new Error('当前函数未被注册'));
  }
  const tempOptions =
    typeof options === 'string' ? options : JSON.stringify(options);
  if (!window.webkit && !window.jsInterface) {
    console.error(new Error('假的融媒体app环境，全部函数不可调用'));
    return false;
  }
  try {
    const method = appMethod[methodKey];
    if (version.ios) {
      window.webkit.messageHandlers[method].postMessage(tempOptions);
    }
    if (version.android) {
      window.jsInterface[method](tempOptions);
    }
  } catch (err) {
    console.error(err);
  }
  return true;
};

/**
 * 打开新闻详情页
 * @param contentType 稿件类型
 * @param id 稿件id
 * @param mListpattern 稿件展示方式
 * @param title 稿件标题
 * @param isChild 是否为子栏目
 * @param detailJsonPath detailjson路径
 */
export const toNewsDetail = (
  contentType: number,
  id: string,
  mListpattern: number,
  title: string,
  isChild: number,
  detailJsonPath?: string,
) => {
  askAppToDo('TO_NEWS_DETAIL', {
    contentType,
    id,
    mListpattern,
    title,
    isChild,
    detailJsonPath: detailJsonPath || '',
  });
};

export const toFunDetail = (params: Record<string, string | number>) => {
  askAppToDo('TO_NEWS_DETAIL', params);
};

/**
 * 调用App原生分享
 * @param shareTitle 分享标题
 * @param shareSummary 分享描述
 * @param shareUrl 分享url
 * @param shareImageUrl 分享图片url
 * @param contentId 稿件id
 * @param shareType 分享的方式 { 0: 海报, 1: 朋友圈, 2: 微信好友, 3: 微博, 4: qq空间, 5: qq }
 */
export const jsNativeShare = (
  shareTitle: string,
  shareSummary: string,
  shareUrl: string,
  shareImageUrl: string,
  contentId: string,
  shareType: 0 | 1 | 2 | 3 | 4 | 5,
) => {
  askAppToDo('JS_NATIVE_SHARE', {
    shareTitle,
    shareSummary,
    shareUrl,
    shareImageUrl,
    contentId,
    shareType,
  });
};

/**
 * 增加投票、报名、问卷调查积分
 * @param type 投票/报名/问卷调查
 */
export const nativeAddCredits = (type: 'vote' | 'survey' | 'entry') => {
  askAppToDo('NATIVE_ADD_CREDITS', type);
};

/**
 * 分享投票人
 * @param title 分享标题
 * @param shareUrl 分享url
 * @param content 分享内容
 */
export const nativeShareElector = (
  title: string,
  shareUrl: string,
  content: string,
) => {
  askAppToDo('NATIVE_SHARE_ELECTOR', {
    title,
    shareUrl,
    content,
  });
};

/**
 * 获取webview宽高
 * @param fn 回调
 */
export const getScreenHeight = (
  fn: (data: GetScreenHeightCallbackResult) => any,
) => {
  window.getScreenHeightCallback = fn;
  askAppToDo('GET_SCREEN_HEIGHT', { callBack: 'getScreenHeightCallback' });
};

/**
 * 打开网页
 * @param url 网页路径
 */
export const nativeOpenWeb = (url: string) => {
  askAppToDo('NATIVE_OPEN_WEB', { url });
};

/**
 * 获取用户定位
 * @param fn 获取定位回调
 */
export const jsGetLocationInfo = (
  fn: (data: JsGetLocationInfoCallbackResult) => any,
) => {
  window.jsGetLocationInfoCallback = fn;
  askAppToDo('JS_GET_LOCATION_INFO', { callBack: 'jsGetLocationInfoCallback' });
};

/**
 * 打开服务页
 * @param type 服务类型
 */
export const nativeOpenServicePage = (data: { [propName: string]: string }) => {
  askAppToDo('NATIVE_OPEN_SERVICE_PAGE', { ...data });
};

/**
 * 上传录音
 * @param path 录音路径
 */
export const jsUploadRecord = (path: string, fn: (url: string) => any) => {
  window.jsSaveServerRecord = fn;
  askAppToDo('JS_UPLOAD_RECORD', { path });
};

/**
 * 停止播放录音
 */
export const jsStopPlayRecord = () => {
  askAppToDo('JS_STOP_PLAY_RECORD');
};

/**
 * 播放录音
 * @param path 录音路径
 * @param fn1 播放录音完成回调
 * @param fn2 播放录音失败回调
 */
export const jsPlayRecord = (path: string, fn1: () => any, fn2: () => any) => {
  window.jsPlayRecordFinish = fn1;
  window.jsPlayRecordError = fn2;
  askAppToDo('JS_PLAY_RECORD', { path });
};

/**
 * 停止录音
 * @param fn 录音完成回调
 */
export const jsStopRecord = (fn: (path: string) => any) => {
  window.jsSaveLocationRecord = fn;
  askAppToDo('JS_STOP_RECORD');
};

/**
 * 开始录音
 */
export const jsStartRecord = () => {
  askAppToDo('JS_START_RECORD');
};

/**
 * 订阅状态刷新
 * @param mediaId 媒体号ID
 * @param isSubscribe 是否关注
 */
export const nativeIsSubscribe = (mediaId: string, isSubscribe: boolean) => {
  askAppToDo('NATIVE_IS_SUBSCRIBE', { mediaId, isSubscribe });
};

/**
 * 打开活动页面
 * @param id 活动id
 * @param type 投票/报名/问卷调查
 */
export const nativeOpenActivityPage = (
  id: string,
  type: 'vote' | 'signUp' | 'questionaire',
) => {
  askAppToDo('NATIVE_OPEN_ACTIVE_PAGE', { id, type });
};

/**
 * 调用语音播放
 * @param id 稿件id
 * @param title 标题
 * @param content 文本内容
 */
export const nativeVoiceBroadcast = (
  id: string,
  title: string,
  content: string,
) => {
  askAppToDo('NATIVE_VOICE_BROADCAST', { id, title, content });
};

/**
 * 调用视频播放
 * @param url 视频地址
 */
export const nativePlayVideo = (url: string) => {
  askAppToDo('NATIVE_PLAY_VIDEO', { url });
};

/**
 * 调用图片浏览器
 * @param imgList 图片链接列表
 */
export const nativePreviewImage = (
  imgList: { url: string; title: string }[],
  index: number,
) => {
  askAppToDo('NATIVE_PREVIEW_IMAGE', { imgList, index });
};

/**
 * 打开媒体号详情页
 * @param mediaId 媒体号id
 * @param type 媒体号类型: 普通/医生媒体号
 */
export const nativeOpenMediaDetailPage = (
  mediaId: string,
  type: 'media' | 'doctor',
) => {
  askAppToDo(
    'NATIVE_OPEN_MEDIA_DETAIL_PAGE',
    type === 'media' ? { mediaId } : { doctorId: mediaId },
  );
};

/**
 * 打开媒体号主页
 */
export const nativeOpenMediaPage = () => {
  askAppToDo('NATIVE_OPEN_MEDIA_PAGE');
};

/**
 * 获取用户手机号
 * @param fn 回调
 */
export const getUserPhone = (fn: (data: GetUserPhoneCallbackResult) => any) => {
  window.getUserPhoneCallback = fn;
  askAppToDo('GET_USER_PHONE', { callBack: 'getUserPhoneCallback' });
};

/**
 * 获取用户userId
 * @param fn 回调
 */
export const getLoginUserId = (
  fn: (data: GetLoginUserIdCallbackResult) => any,
) => {
  window.getLoginUserIdCallback = fn;
  askAppToDo('GET_LOGIN_USER_ID', { callBack: 'getLoginUserIdCallback' });
};

/**
 * 获取用户userId
 * @param fn 回调
 */
export const getUserInfo = (fn: (data: GetUserInfoCallbackResult) => any) => {
  window.getUserInfoCallback = fn;
  askAppToDo('GET_USER_INFO', { callBack: 'getUserInfoCallback' });
};

/**
 * 活动中心获取token
 * @param fn 回调
 */
export const jsDRGetAccessToken = (
  fn: (data: JsDRGetAccessTokenCallbackResult) => any,
) => {
  window.JsDRGetAccessTokenCallback = fn;
  askAppToDo('JS_DR_GET_ACCESS_TOKEN', {
    callBack: 'JsDRGetAccessTokenCallback',
  });
};

export const toTopic = (id: string, isChild: number, detailJson: string) => {
  if (browerVersion().ios) {
    askAppToDo('TO_TOPIC', id);
  } else {
    window.jsInterface.toTopic(id, isChild, detailJson);
  }
};

export const nativeScanImage = (url: string) => {
  askAppToDo('NATIVE_SCAN_IMAGE', { url });
};
