import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Cookie from 'js-cookie';

/**
 * 判断当前浏览器的类型
 * @returns
 */
export const browerVersion = () => {
  const { userAgent } = navigator;
  const userIdMatchs = userAgent.match(/userId\/(.*?) /);
  const weixinVersion = userAgent.match(/MicroMessenger\/([\d.]+)/i);
  return {
    trident: userAgent.indexOf('Trident') > -1, // IE内核
    presto: userAgent.indexOf('Presto') > -1, // opera内核
    webKit: userAgent.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: userAgent.indexOf('Gecko') > -1 && userAgent.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!userAgent.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1, // android终端
    iPhone: userAgent.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: userAgent.indexOf('iPad') > -1, // 是否iPad
    webApp: userAgent.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
    weixin: userAgent.indexOf('MicroMessenger') > -1, // 是否微信
    myApp: userAgent.indexOf('pdmiryun') > -1, // 在app里面打开
    rmcb: userAgent.indexOf('rmcb') > -1, // 在融媒采编app里面打开
    userId: userIdMatchs?.length ? userIdMatchs[1].replace(/ /g, '') : '',
    iframe: window.self !== window.top,
    windowWeixin: userAgent.indexOf('WindowsWechat') > -1,
    macWeixin: userAgent.indexOf('MacWechat') > -1,
    wxVersion: weixinVersion && weixinVersion.length ? weixinVersion[1] : 0,
    wxWork: userAgent.indexOf('WxWork') > -1,
    miniProgram: userAgent.indexOf('miniProgram') > -1,
    harmonyOS:
      userAgent.includes('OpenHarmony') || userAgent.includes('ArkWeb'),
  };
};

/**
 * 处理小于10的数字
 * @param num
 * @returns
 */
const formatLessThan10Num = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

/**
 * 获取最近几分钟时间
 * @param num
 * @returns
 */
export const getLastFewMinutes = (num: 5) => {
  const time = new Date();
  const year = time.getFullYear();
  const month = formatLessThan10Num(time.getMonth() + 1);
  const date = formatLessThan10Num(time.getDate());
  const hour = formatLessThan10Num(time.getHours());
  const minute = time.getMinutes();
  const lastFewminute = formatLessThan10Num(minute - (minute % num));
  return `${year}${month}${date}${hour}${lastFewminute}`;
};

/**
 * 获取url上的query参数
 * @returns
 */
export const getUrlQuery = (url = window.location.href) => {
  const search = url.split('?')[1];
  if (search) {
    const keyValueArr = search.split('&');
    const query: { [propName: string]: string } = {};
    keyValueArr.forEach((keyValue) => {
      const [key, value] = keyValue.split('=');
      query[key] = decodeURIComponent(value);
    });
    return query;
  }
  return {};
};

/**
 * 通过融媒体新闻url获取参数
 * @param newsUrl
 * @returns
 */
export const getRmtNewsUrlParams = (
  newsUrl: string,
): RMT.RmtUrlParams | false => {
  try {
    const newsUrlArr = newsUrl.split('/');
    let newsUrlArrIndex = -1;
    const reg = /^[0-9]+$/;
    for (let i = 0; i < newsUrlArr.length; i += 1) {
      if (reg.test(newsUrlArr[i])) {
        newsUrlArrIndex = i;
        break;
      }
    }
    // eslint-disable-next-line no-useless-escape
    const domainReg =
      /http[s]*:\/\/[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;
    const demainResult = newsUrl.match(domainReg);
    return {
      year: newsUrlArr[newsUrlArrIndex],
      month: newsUrlArr[newsUrlArrIndex + 1],
      date: newsUrlArr[newsUrlArrIndex + 2],
      newsId: newsUrlArr[newsUrlArrIndex + 3].split('.')[0],
      domain: demainResult ? demainResult[0] : '',
    };
  } catch (err) {
    console.error(`${newsUrl} is not a correct url`);
    return false;
  }
};

/**
 * 获取UUID
 * @returns
 */
export const uuid = () => {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 32; i += 1) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';
  s[19] = hexDigits.substr((s[19] && 0x3) || 0x8, 1);
  return s.join('');
};

interface LoadScriptOptions {
  url: string;
  name: string;
  loadFn?: () => any;
  errFn?: (err: any) => any;
}
/**
 * 动态引入script文件
 * @param url script
 * @param loadFn script加载完成的回调
 * @param errFn script加载失败的回调
 */
export const loadScript = (options: LoadScriptOptions) => {
  const { url, name, loadFn, errFn } = options;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.setAttribute('name', name);
  script.onerror = (err) => {
    console.error(err);
    if (errFn) {
      errFn(err);
    }
  };
  if (loadFn) {
    script.onload = loadFn;
  }
  script.src = url;
  document.getElementsByTagName('html')[0].append(script);
};

type SingleValueKeyType =
  | 'mainDomain'
  | 'subDomain'
  | 'siteId'
  | 'shareTitle'
  | 'shareDesc'
  | 'shareImgUrl'
  | 'staticType'
  | 'contentType'
  | 'mListpattern'
  | 'newsId'
  | 'channelId'
  | 'audioSrc'
  | 'audioDuration'
  | 'mediaId'
  | 'isChild'
  | 'topicJson'
  | 'liveJson'
  | 'noLiveJson'
  | 'noLiveJsonDesc'
  | 'commentJson'
  | 'liveType'
  | 'liveState'
  | 'defaultTab';
/**
 * 获取html中预设的字段
 * @param name
 * @returns
 */
export const getSingleValueFromInput = (name: SingleValueKeyType): string => {
  let inputValue = '';
  const list = document.getElementsByName(name);
  if (list.length) {
    for (let i = 0; i < list.length; i += 1) {
      const item = list[i] as HTMLInputElement;
      if (item.value) {
        inputValue = item.value;
        break;
      }
    }
  }
  return inputValue;
};

type ArrayValueType = 'coverImage';
/**
 * 获取html中预设的字段列表
 * @param name
 * @returns
 */
export const getArrayValueFromInput = (name: ArrayValueType): string[] => {
  const inputValue = [];
  const list = document.getElementsByName(name);
  if (list.length) {
    for (let i = 0; i < list.length; i += 1) {
      const item = list[i] as HTMLInputElement;
      if (item.value) {
        inputValue.push(item.value);
        break;
      }
    }
  }
  return inputValue;
};

/* eslint-disable */
export const initYoumeng = () => {
  if (window.location.host !== process.env.DEFAULT_YOUMENG_DOMAIN) {
    return;
  }
  var _czc: any = _czc || [];
  (function () {
    const um = document.createElement('script');
    um.src = `https://v1.cnzz.com/z.js?id=${process.env.DEFAULT_YOUMENG_ID}&async=1`;
    document.body.append(um);
  })();
};
/* eslint-enable */

/**
 * 获取浏览器指纹
 * @param fn
 */
export const getBrowerAppId = async (fn: (appid: string) => void) => {
  const cacheAppId = Cookie.get('fingerprint');
  if (cacheAppId) {
    fn(cacheAppId);
  } else {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const { visitorId } = await fp.get();
    Cookie.set('fingerprint', visitorId);
    fn(visitorId);
  }
};

/**
 * 设置字体大小
 * @param fontSize
 */
export const initRootFontSize = (fontSize: number) => {
  document.getElementsByTagName('html')[0].style.fontSize = `${fontSize}px`;
};

/**
 * 判断是否在白名单域名的iframe中
 */
const whiteParentDomain = ['gz-cmc.com', 'gzcmc.net', 'gz-cmc.cn'];
export const isHideDownloadGuide = () => {
  if (browerVersion().miniProgram) {
    return true;
  }
  /* eslint-disable no-restricted-globals */
  if (window.frames.length !== parent.frames.length) {
    let url = '';
    if (parent !== window) {
      try {
        url = parent.location.href;
      } catch (e) {
        url = document.referrer;
      }
    }
    const urlReg =
      /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;
    const urlExec = urlReg.exec(url);
    if (urlExec && urlExec.length) {
      const domain = urlExec[0];
      return whiteParentDomain.some((item) => domain.includes(item));
    }
  }
  return false;
  /* eslint-enable no-restricted-globals */
};
