import $ from 'jquery';

const AudioPlayIcon = require('../assets/images/audio-play.png');

type ArticleAudioValue = {
  isPlaying: boolean;
  audio: HTMLAudioElement;
  initX: number;
  step: number;
  isTouching: boolean;
};
const articleAudios = {} as { [propName: string]: ArticleAudioValue };
let audioTouchingId = '';

/**
 * 将audio标签移除并更换成新的UI效果
 * @param index
 * @param el
 */
const setCustomAudio = (index: number, el: HTMLAudioElement | HTMLElement) => {
  const currentTagName = $(el)[0].tagName;
  let audioEl = el;
  if (currentTagName.toLocaleLowerCase() !== 'audio') {
    [audioEl] = $(el).find('audio');
  }
  const {
    src,
    dataset: { time },
  } = audioEl as HTMLAudioElement;
  const playbackRateText = `<span class="audio-playback-rate-text">播放倍速: <span class="audio-playback-rate">1.0</span></span>`;
  const audioProgressTime = `<span><span class="audio-current-text">00:00:00</span> / <span class="audio-duration">${time}</span></span>`;
  const audioProgressText = `<div class="audio-progress-text">${playbackRateText}${audioProgressTime}</div>`;
  const audioActiveProgress = '<div class="audio-active-progress"></div>';
  const audioProgressBar = `<div class="audio-progress-bar">${audioActiveProgress}</div>`;
  const audioProgressBox = `<div class="audio-progress-box">${audioProgressText}${audioProgressBar}</div>`;
  const audioControlBox = `<div class="audio-control-box"><img class="audio-control-btn" src="${AudioPlayIcon}" alt="音频播放/暂停"></div>`;
  const audioArticleAudioBox = `<div id="article-audio-${index}" class="article-audio-box" data-src="${src}" data-time="${time}">${audioControlBox}${audioProgressBox}</div>`;
  $(el).replaceWith(audioArticleAudioBox);
};
const formatLess10 = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};
/**
 * 计算当前时间长度的时:分:秒
 * @param time
 * @returns
 */
const formatTime = (time: number) => {
  const timeInt = Math.floor(time);
  const h = Math.floor(timeInt / 3600);
  const m = Math.floor((timeInt / 60) % 60);
  const s = Math.floor(timeInt % 60);
  return `${formatLess10(h)}:${formatLess10(m)}:${formatLess10(s)}`;
};

/**
 * 设置进度条长度
 * @param audioBox
 * @param clientX
 * @returns
 */
const setProgress = (audioBox: HTMLElement, clientX: number) => {
  if (!clientX) {
    return;
  }
  const { audio, initX, step } = articleAudios[audioTouchingId];
  const moveX = clientX - initX;
  $(audioBox)
    .find('.audio-active-progress')
    .css({ width: `${moveX}px` });
  audio.currentTime = Math.floor(moveX * step);
  $(audioBox)
    .find('.audio-current-text')
    .text(formatTime(Math.round(audio.currentTime)));
};
/**
 * 监听进度条touchstart/mousedown事件
 * @param evt
 */
const audioTouchStart = (evt: Event) => {
  const audioBox = $(evt.currentTarget as EventTarget).parents(
    '.article-audio-box',
  )[0];
  const { id } = audioBox;
  articleAudios[id].isTouching = true;
  audioTouchingId = id;
  let clientX = 0;
  if (evt.type === 'touchstart') {
    clientX = (evt as TouchEvent).touches[0].clientX;
  } else if (evt.type === 'mousedown') {
    clientX = (evt as MouseEvent).clientX;
  }
  setProgress(audioBox, clientX);
};
/**
 * 监听进度条touchmove/mousemove事件
 * @param evt
 * @returns
 */
const audioTouchMove = (evt: Event) => {
  if (!audioTouchingId) {
    return;
  }
  const audioBox = $(evt.currentTarget as EventTarget).parents(
    '.article-audio-box',
  )[0];
  let clientX = 0;
  if (evt.type === 'touchmove') {
    clientX = (evt as TouchEvent).touches[0].clientX;
  } else if (evt.type === 'mousemove') {
    clientX = (evt as MouseEvent).clientX;
  }
  setProgress(audioBox, clientX);
};
/**
 * 监听进度条touchend/mouseup事件
 * @returns
 */
const audioTouchEnd = () => {
  if (!audioTouchingId) {
    return;
  }
  articleAudios[audioTouchingId].isTouching = false;
  audioTouchingId = '';
};

/**
 * 初始化音频组件
 * @param doms
 */
const initAudio = (doms: JQuery<HTMLElement>) => {
  doms.each((index, el) => {
    setCustomAudio(index, el);
  });
  $('.audio-active-progress').each((index, el) => {
    const rect = el.getBoundingClientRect();
    const audioProgressBar = $(el).parents('.audio-progress-bar')[0];
    const { offsetWidth } = audioProgressBar;
    const articleAudioBox = $(el).parents('.article-audio-box')[0];
    const {
      id,
      dataset: { src, time },
    } = articleAudioBox;
    let seconds = 0;
    if (time) {
      const timeArr = time.split(':').map((item) => parseInt(item, 10));
      if (timeArr.length === 3) {
        seconds = timeArr[0] * 3600 + timeArr[1] * 60 + timeArr[2];
      }
    }
    const audio = new Audio(src);
    // 初始化articleAudios对象
    articleAudios[id] = {
      isPlaying: false,
      audio,
      initX: rect.x,
      step: Math.floor((seconds / offsetWidth) * 10000) / 10000,
      isTouching: false,
    };
    // 监听audio播放进度
    audio.ontimeupdate = () => {
      if (!articleAudios[id].step) {
        const duration = Math.floor(audio.duration);
        const step = Math.floor((duration / offsetWidth) * 10000) / 10000;
        articleAudios[id].step = step;
        $(articleAudioBox).find('.audio-duration').text(formatTime(duration));
      }
      if (articleAudios[id].isTouching) {
        return;
      }
      const currentWidth = Math.round(
        audio.currentTime / articleAudios[id].step,
      );
      $(articleAudioBox)
        .find('.audio-active-progress')
        .css({ width: `${currentWidth}px` });
      $(articleAudioBox)
        .find('.audio-current-text')
        .text(formatTime(Math.round(audio.currentTime)));
    };
    // 监听audio播放
    audio.onplay = () => {
      articleAudios[id].isPlaying = true;
      $(`#${id}`).find('.audio-control-btn').addClass('audio-control-playing');
    };
    // 监听audio暂停
    audio.onpause = () => {
      articleAudios[id].isPlaying = false;
      $(`#${id}`)
        .find('.audio-control-btn')
        .removeClass('audio-control-playing');
    };
    // 监听audio停止播放
    audio.onended = () => {
      articleAudios[id].isPlaying = false;
      audio.currentTime = 0;
      $(`#${id}`)
        .find('.audio-control-btn')
        .removeClass('audio-control-playing');
    };
  });
  // 监听播放/暂停按钮点击
  $('.audio-control-btn').on('click', (evt) => {
    const articleAudioBox = $(evt.currentTarget).parents(
      '.article-audio-box',
    )[0];
    const { id } = articleAudioBox;
    const { audio } = articleAudios[id];
    if (!articleAudios[id].isPlaying) {
      Object.values(articleAudios).forEach((value) => {
        if (value.isPlaying) {
          value.audio.pause();
        }
      });
      audio.play();
    } else {
      audio.pause();
    }
  });
  const audioProgressBar = document.querySelector('.audio-progress-bar');
  if (audioProgressBar) {
    audioProgressBar.addEventListener('mousedown', audioTouchStart, {
      passive: true,
    });
    audioProgressBar.addEventListener('touchstart', audioTouchStart, {
      passive: true,
    });
    window.addEventListener('mousemove', audioTouchMove, {
      passive: true,
    });
    audioProgressBar.addEventListener('touchmove', audioTouchMove, {
      passive: true,
    });
    window.addEventListener('mouseup', audioTouchEnd, {
      passive: true,
    });
    audioProgressBar.addEventListener('touchend', audioTouchEnd, {
      passive: true,
    });
  }
  const audioPlaybackRateText = document.querySelector(
    '.audio-playback-rate-text',
  );
  const audioPlaybackRate = document.querySelector(
    '.audio-playback-rate',
  ) as HTMLElement;
  const rateArr = ['1.0', '1.25', '1.5', '1.75', '0.75'];
  if (audioPlaybackRate && audioPlaybackRateText) {
    audioPlaybackRateText.addEventListener('click', (evt) => {
      const rate = audioPlaybackRate.innerText;
      const rateIndex = rateArr.findIndex(
        (item) => Number(rate) === Number(item),
      );
      let newRate = '1.0';
      if (rateIndex === -1) {
        audioPlaybackRate.innerText = `${rateArr[0]}`;
      } else {
        newRate = rateArr[(rateIndex + 1) % rateArr.length];
        audioPlaybackRate.innerText = `${
          rateArr[(rateIndex + 1) % rateArr.length]
        }`;
      }
      const audioBox = $(evt.currentTarget as EventTarget).parents(
        '.article-audio-box',
      )[0];
      const { id } = audioBox;
      const { audio } = articleAudios[id];
      audio.playbackRate = Number(newRate);
    });
  }
};

export default initAudio;
