import qs from 'qs';
import { getLastFewMinutes, getSingleValueFromInput } from '../ts/utils';
/**
 * 获取微信分享信息
 * @returns
 */
export const fetchWxConfig = async () => {
  const url = window.location.href.split('#')[0];
  const mainDomain = getSingleValueFromInput('mainDomain');
  const res = await fetch(
    `${mainDomain}/auxiliaryapi/api/content/wxShare/wechatParam?${qs.stringify({
      url,
    })}`,
  );
  return res.json();
};

/**
 * 上传错误日志
 */
export const fetchLogRecord = async (data: any) => {
  const mainDomain = getSingleValueFromInput('mainDomain');
  const res = await fetch(`${mainDomain}/readapi/log/record`, {
    method: 'POST',
    body: JSON.stringify({
      detail: JSON.stringify(data),
    }),
  });
  return res.json();
};

/**
 * 获取siteJson
 * @returns
 */
export const fetchSiteJson = async (): Promise<Site.SiteJson> => {
  const mainDomain = getSingleValueFromInput('mainDomain');
  const siteId = getSingleValueFromInput('siteId');
  const res = await fetch(`${mainDomain}/json/config/${siteId}/site.sitejson`);
  return res.json();
};

/**
 * 获取频道信息
 * @returns
 */
export const fetchChannleJson = async (): Promise<RMT.Channel> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/json/channel/${process.env.RELATED_CHANNEL_CODE}.channeljson`,
  );
  return res.json();
};

/**
 * 根据频道code获取50条稿件列表
 * @returns
 */
export const fetchChannelNewsJson = async (
  code: string,
): Promise<RMT.ListJson> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(`${subDomain}/json/channel/${code}/list.json`);
  return res.json();
};

/**
 * 获取媒体号信息
 * @param siteId
 * @param mediaId
 * @param appId
 * @param userId
 * @returns
 */
export const fetchMediaInfoV3 = async (
  siteId: string,
  mediaId: string,
  appId: string,
  userId: string,
): Promise<Media.MediaData> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/mpapi/api/mp/media/getMediaInfoV3?${qs.stringify({
      siteId,
      mediaId,
      appId,
      userId,
    })}`,
  );
  return res.json();
};

/**
 * 关注媒体号
 * @param siteId
 * @param mediaId
 * @param appId
 * @param userId
 * @returns
 */
export const fetchAddSubscribe = async (
  siteId: string,
  mediaId: string,
  appId: string,
  userId: string,
): Promise<{ status: string; msg: string }> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(`${subDomain}/mpapi/api/mp/media/addSubscribe`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: qs.stringify({ siteId, mediaId, appId, userId }),
  });
  return res.json();
};

/**
 * 取消关注媒体号
 * @param siteId
 * @param mediaId
 * @param appId
 * @param userId
 * @returns
 */
export const fetchDelSubscribe = async (
  siteId: string,
  mediaId: string,
  appId: string,
  userId: string,
): Promise<{ status: string; msg: string }> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(`${subDomain}/mpapi/api/mp/media/delSubscribe`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: qs.stringify({ siteId, mediaId, appId, userId }),
  });
  return res.json();
};

/**
 * 增加阅读量
 * @param id
 * @param type
 * @param siteId
 * @returns
 */
export const fetchAddReadCountByLog = async (
  id: string,
  type: number,
  siteId: string,
) => {
  const subDomain = getSingleValueFromInput('subDomain');
  await fetch(
    `${subDomain}/contentapi/api/content/addReadCountByLog?${qs.stringify({
      id,
      type,
      platform: 'h5',
      siteId,
    })}`,
  );
};

/**
 * 获取评论列表
 * @param id
 * @param siteId
 * @param appId
 * @param pageNum
 * @param pageSize
 * @returns
 */
export const fetchCommentList = async (
  id: string,
  siteId: string,
  appId: string,
  pageNum = 1,
  pageSize = 3,
): Promise<RMT.Comment> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/content/getCommentList?${qs.stringify({
      contentId: id,
      siteId,
      appId,
      pageNum,
      pageSize,
    })}`,
  );
  return res.json();
};

/**
 * 获取阅读量、点赞量等参数
 * @param id
 * @param channelId
 * @returns
 */
export const fetchContentPropertie = async (id: string, channelId: string) => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/content/getContentPropertieH5V2?${qs.stringify(
      {
        contentId: id,
        platform: 'h5',
        channelId,
      },
    )}`,
  );
  return res.json();
};

/**
 * 获取相关稿件
 * @param id
 * @returns
 */
export const fetchRelatedContent = async (
  id: string,
): Promise<RMT.RelatedNews> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/content/getRelatedContent?${qs.stringify({
      contentId: id,
      platform: 'h5',
    })}`,
  );
  return res.json();
};

/**
 * 获取媒体号信息
 * @param mediaId
 * @param appId
 * @param siteId
 * @returns
 */
export const fetchMediaInfoV2 = async (
  mediaId: string,
  appId: string,
  siteId: string,
): Promise<Media.MediaData> => {
  const domain = getSingleValueFromInput('mainDomain');
  const res = await fetch(
    `${domain}/mpapi/api/mp/media/getMediaInfoV2?${qs.stringify({
      mediaId,
      appId,
      siteId,
    })}`,
  );
  return res.json();
};

/**
 * 根据contentTypes获取媒体号稿件列表
 * @param mediaId
 * @param appId
 * @param siteId
 * @param contentTypes
 * @param pageNum
 * @param pageSize
 * @param isOwner
 * @returns
 */
export const fetchContentListByType = async (
  mediaId: string,
  appId: string,
  siteId: string,
  contentTypes: string,
  pageNum: number,
  pageSize: number,
  isOwner: number,
): Promise<Media.Content> => {
  const domain = getSingleValueFromInput('mainDomain');
  const res = await fetch(
    `${domain}/mpapi/api/mp/content/getContentListByType?${qs.stringify({
      mediaId,
      appId,
      siteId,
      contentTypes,
      pageNum,
      pageSize,
      isOwner,
    })}`,
  );
  return res.json();
};

/**
 * 获取信息时报版面
 * @returns
 */
export const fetchXXSB = async (): Promise<Epaper.XXSB[]> => {
  const res = await fetch(
    'https://epaper.xxsb.com/web/index.php/api/Paper/GetColumnsList',
  );
  return res.json();
};

/**
 * 获取广州日报稿件
 * @returns
 */
export const fetchGZRB = async (): Promise<Epaper.GZRB> => {
  const time = new Date();
  const year = time.getFullYear();
  let month: number | string = time.getMonth() + 1;
  let date: number | string = time.getDate();
  month = month < 10 ? `0${month}` : `${month}`;
  date = date < 10 ? `0${date}` : `${date}`;
  const current = `${year}-${month}-${date}`;
  const res = await fetch(
    `https://newspaper.gzdaily.cn/pc/wxapp/paperJson/${current}/${current}.json`,
  );
  return res.json();
};

/**
 * 获取专题详情 topicJson
 * @param url
 * @returns
 */
export const fetchTopicJson = async (url: string): Promise<RMT.TopicJson> => {
  const res = await fetch(`${url}?t=${getLastFewMinutes(5)}`);
  return res.json();
};

/**
 * 获取专题子栏目稿件
 * @param url
 * @returns
 */
export const fetchGroupJson = async (url: string): Promise<RMT.GroupJson> => {
  const res = await fetch(`${url}?t=${getLastFewMinutes(5)}`);
  return res.json();
};

/**
 * 获取内容详情
 * @param id
 * @param appId
 * @param contentType
 * @returns
 */
export const fetchContentDetail = async (
  id: string,
  appId: string,
  contentType: number,
): Promise<RMT.TopicContentDetail> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/content/getContentDetail?${qs.stringify({
      id,
      appId,
      contentType,
    })}`,
  );
  return res.json();
};

/**
 * 获取专题子栏目列表
 * @param topicId
 * @returns
 */
export const fetchTopicChannel = async (
  topicId: string,
): Promise<RMT.TopicChannelDetail> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/topic/getTopicChannels?${qs.stringify({
      topicId,
    })}`,
  );
  return res.json();
};

/**
 * 获取子栏目稿件
 * @param groupId
 * @param siteId
 * @param pageNum
 * @param pageSize
 * @param pages
 * @returns
 */
export const fetchTopicChannelContent = async (
  groupId: string,
  siteId: string,
  pageNum: number,
  pageSize: number,
  pages: number,
): Promise<RMT.TopicChannelContentDetail> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/topic/getTopicChannelContentList?${qs.stringify(
      {
        groupId,
        pageNum,
        pages,
        pageSize,
        siteId,
        platform: 'h5',
      },
    )}`,
  );
  return res.json();
};

/**
 * 获取频道稿件
 * @param topicId
 * @param siteId
 * @param pageNum
 * @param pageSize
 * @param pages
 * @returns
 */
export const fetchTopicContent = async (
  topicId: string,
  siteId: string,
  pageNum: number,
  pageSize: number,
  pages: number,
): Promise<RMT.TopicContentNewsDetail> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/topic/getTopicContents?${qs.stringify({
      topicId,
      pageNum,
      pages,
      pageSize,
      siteId,
      platform: 'h5',
    })}`,
  );
  return res.json();
};

/**
 * 获取评论列表
 * @param url
 * @returns
 */
export const fetchCommentJson = async (
  url: string,
): Promise<RMT.LiveComment> => {
  const res = await fetch(`${url}?t=${getLastFewMinutes(5)}`);
  return res.json();
};

/**
 * 获取多现场直播数据
 * @param url
 * @returns
 */
export const fetchLiveJson = async (url: string): Promise<RMT.LiveJson> => {
  const res = await fetch(`${url}?t=${getLastFewMinutes(5)}`);
  return res.json();
};

/**
 * 获取图文直播
 * @param url
 * @returns
 */
export const fetchNoLiveJson = async (url: string): Promise<RMT.NoLiveJson> => {
  const res = await fetch(`${url}?t=${getLastFewMinutes(5)}`);
  return res.json();
};

/**
 * 获取直播阅读数
 * @param id
 * @returns
 */
export const fetechSceneVisitCount = async (
  id: string,
): Promise<RMT.SceneVisitCount> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const res = await fetch(
    `${subDomain}/contentapi/api/content/getSceneVisitCount?id=${id}`,
  );
  return res.json();
};

/**
 * 通过接口获取图文直播内容
 * @param id
 * @param pageNum
 * @param pageSize
 * @returns
 */
export const fetchPreviewNoliveReport = async (
  id: string,
  pageNum = 1,
  pageSize = 10,
): Promise<RMT.PreviewNoLiveReport> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const siteId = getSingleValueFromInput('siteId');
  const res = await fetch(
    `${subDomain}/sceneapi/api/scene/live/getNoLiveReportList?liveId=${id}&pageNum=${pageNum}&pageSize=${pageSize}&siteId=${siteId}`,
  );
  return res.json();
};

/**
 * 通过接口获取评论列表
 * @param id
 * @param pageNum
 * @param pageSize
 * @returns
 */
export const fetchPreviewComment = async (
  id: string,
  pageNum = 1,
  pageSize = 10,
): Promise<RMT.PreviewLiveComment> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const siteId = getSingleValueFromInput('siteId');
  const res = await fetch(
    `${subDomain}/sceneapi/api/scene/live/getCommentList?liveId=${id}&pageNum=${pageNum}&pageSize=${pageSize}&siteId=${siteId}`,
  );
  return res.json();
};

/**
 * 通过接口获取直播多现场
 * @param id
 * @returns
 */
export const fetchPreviewLiveReport = async (
  id: string,
): Promise<RMT.PreviewLiveReport> => {
  const subDomain = getSingleValueFromInput('subDomain');
  const siteId = getSingleValueFromInput('siteId');
  const res = await fetch(
    `${subDomain}/sceneapi/api/scene/live/getLiveReportList?liveId=${id}&siteId=${siteId}`,
  );
  return res.json();
};

export const fetchBelongTopic = async (
  contentId: string,
  pageNum = 1,
  pageSize = 20,
): Promise<RMT.RelatedNews> => {
  const mainDomain = getSingleValueFromInput('mainDomain');
  const res = await fetch(
    `${mainDomain}/contentapi/api/topic/getBelongTopic?contentId=${contentId}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
  return res.json();
};
